import get from "lodash/get";
import Form from "models/Form";
import FormRecord, { FormValues, IDataValue } from "models/FormRecord";

export const buildExternalContext = async (form: Form, assetId: string) => {
	const baseContext: { [formId: string]: FormValues } = {};
	if (form.externalDependencies && form.externalDependencies.length) {
		const otherFormRecords = await FormRecord.byAssetId(assetId);
		for (const { formId, dependencies } of form.externalDependencies) {
			const form = otherFormRecords
				.filter((it) => !it.is_deleted)
				.sort((a, b) => b.date_updated.toISOString().localeCompare(a.date_updated.toISOString()))
				.find((it) => it.form_id === formId);
			const context = Object.fromEntries(
				Object.entries(dependencies).map(([key, fullpath]) => [key, get(form?.data, fullpath) as IDataValue]),
			);
			baseContext[formId] = context;
		}
	}
	return baseContext;
};
