import React, { memo, PropsWithChildren } from "react";

import { IonIcon, IonInput, IonItem, IonLabel, IonNote, IonText } from "@ionic/react";
import { Controller } from "react-hook-form";

import { FormField } from "../../../../models/Form";
import { alertCircleOutline, informationCircleOutline } from "ionicons/icons";
import { Divider } from "../components/Divider";
import { useSmartFieldCtx } from "../hooks/useSmartForm";

interface IProps {
	field: FormField;
}

type PropsType = IProps;

const Calculation: React.FC<PropsType> = (props) => {
	const { field } = props;

	const { dig, control } = useSmartFieldCtx(field);

	const { name, alert, relevant, required, hardRequired, hidden } = dig;

	return (
		<div style={{ display: relevant && !hidden ? undefined : "none" }}>
			<Controller
				name={name}
				control={control}
				rules={{
					required: hardRequired && relevant,
				}}
				render={({ field: fieldRenderProps }) => {
					return (
						<IonItem
							lines="none"
							style={{
								backgroundColor: "var(--ion-background-color)",
							}}
						>
							<IonLabel className="ion-text-wrap" position="stacked" mode="ios" color="medium">
								{field.label}
								{(required || hardRequired) && <span style={{ color: "red" }}>&nbsp;*</span>}
							</IonLabel>
							<IonInput
								aria-label={field.label}
								data-testid={`${field.type}:input`}
								// React Hook Form Managed
								ref={fieldRenderProps.ref}
								name={fieldRenderProps.name}
								value={fieldRenderProps.value as string}
								style={{ color: "var(--ion-color-medium)" }}
								readonly
							/>
							<Divider color="var(--ion-color-medium)" />
							{alert && (
								<IonNote
									data-testid={`${field.type}:note`}
									color={field.alertColor}
									style={{
										display: "flex",
										gap: ".25rem",
										alignItems: "center",
										textAlign: "center",
										margin: ".25rem 0",
									}}
								>
									<IonIcon
										icon={
											field.alertIcon === "informationCircleOutline" ? informationCircleOutline : alertCircleOutline
										}
										color={field.alertColor}
										size="small"
									/>
									<IonText style={{ textAlign: "start", lineHeight: 1 }}>{alert}</IonText>
								</IonNote>
							)}
						</IonItem>
					);
				}}
			/>
		</div>
	);
};
const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(Calculation, propsAreEqual);
export default Memoized;
