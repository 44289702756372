import get from "lodash/get";
import Asset from "models/Asset";
import FormRecord from "models/FormRecord";
import { useEffect, useState } from "react";

export const useGetAssetName = (projectRef: string, assets: Asset[]) => {
	const [nameDictionary, setNameDictionary] = useState<Record<string, string | undefined>>({});

	useEffect(() => {
		const init = async () => {
			if (projectRef === "adif-ite" || projectRef === "qa-test") {
				const newNameDictionary: Record<string, string> = {};
				const formIds = ["adif-ite-inventario", "adif-ite-inventario-v2"];
				const records = await FormRecord.getAll(); // Don't include deleted or inactive stuff
				assets.forEach((asset) => {
					const found = records.find((record) => formIds.includes(record.form_id) && record.asset_id === asset.id);

					if (found) {
						const name = get(found.data, "datosGeneralesActivoFichaInventario.nombreActivo");

						if (name && name !== "") {
							newNameDictionary[asset.id] = String(name);
						} else {
							newNameDictionary[asset.id] = String(asset.name);
						}
					} else {
						newNameDictionary[asset.id] = String(asset.name);
					}
				});
				setNameDictionary(newNameDictionary);
			} else {
				setNameDictionary(
					assets.reduce((acc, current) => {
						acc[current.id] = current.name;
						return acc;
					}, {} as Record<string, string>),
				);
			}
		};
		init();
	}, [assets.length]); // solo lo hace una vez??? la primera vez es [], la segunda vez son 9.

	return { nameDictionary };
};
