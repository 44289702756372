import get from "lodash/get";
import FormRecord, { FormValues, IDataValue } from "models/FormRecord";
import { ConfigurationFilter } from "models/Project";

export function checkRecordAgainstFilter(
	filterConfig: ConfigurationFilter,
	record: FormRecord,
	filter: FormValues<IDataValue>,
): boolean {
	for (const path of filterConfig.fields) {
		const dataValue = get(record.data, path);
		const pathList = path.split(".");
		const lastItem = pathList[pathList.length - 1];

		if (Array.isArray(dataValue)) {
			const actual = dataValue.map(String);
			const expected = filter[lastItem] as Array<string>;
			const expectedIsEmpty = expected === undefined || expected === null;
			if (expectedIsEmpty) continue;
			const intersection = actual.filter((it) => expected.indexOf(it) > -1);
			if (!intersection.length) return false;
		} else {
			const actualString = String(dataValue || "").toLowerCase();
			const expected = String(get(filter, lastItem) || "").toLowerCase();
			if (expected === "") continue;
			if (!actualString.includes(expected)) return false;
		}
	}
	return true;
}
