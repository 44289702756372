import React, { memo, PropsWithChildren } from "react";

import { IonIcon, IonItem, IonLabel, IonNote, IonRadio, IonRadioGroup, IonText } from "@ionic/react";
import { Controller } from "react-hook-form";

import { FormField } from "models/Form";

import { alertCircleOutline, informationCircleOutline } from "ionicons/icons";
import { useAppSelector } from "store";
import { useSmartFieldCtx } from "../hooks/useSmartForm";
import { useFilterFieldCtx } from "../hooks/useFilterForm";

interface IProps {
	field: FormField<string>;
	isFilterWidget?: boolean;
}

type PropsType = IProps;
const RadioGroup: React.FC<PropsType> = (props) => {
	const { field, isFilterWidget } = props;

	const isFrozen = useAppSelector((state) => state.form.isFrozen);
	const { dig, control, currentValue } = isFilterWidget ? useFilterFieldCtx(field) : useSmartFieldCtx(field);

	const { name, relevant, required, hardRequired, choices, alert, disabled, isDisabled, hidden } = dig;

	// This is necessary because when the current value is false the required rule was giving a false error.
	// This can happen when you have a RadioGroup with true and false choices.
	const valueIsBoolean = typeof currentValue === "boolean";

	const validChoices = choices || [];
	if (field.sortChoices === "ascending") validChoices.sort((a, b) => a.label.localeCompare(b.label));
	else if (field.sortChoices === "descending") validChoices.sort((a, b) => b.label.localeCompare(b.label));

	return (
		<div
			className="Input"
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{relevant && !hidden && (
				<Controller
					name={name}
					control={control}
					rules={{
						required: {
							value: !!hardRequired && !!relevant && !valueIsBoolean,
							message: "RadioGroup hardRequired",
						},
					}}
					defaultValue={field.defaultValue !== undefined ? String(field.defaultValue) : undefined}
					render={({ field: fieldRenderProps }) => {
						return (
							<IonItem
								lines="none"
								disabled={isFrozen || disabled}
								style={{
									backgroundColor: "transparent",
								}}
							>
								<IonLabel className="ion-text-wrap" position="stacked" mode="ios" style={{ paddingBottom: "0.1rem" }}>
									{field.label}
									{(required || hardRequired) && <span style={{ color: "red" }}>&nbsp;*</span>}
								</IonLabel>
								<IonRadioGroup
									// React Hook Form Managed
									ref={fieldRenderProps.ref}
									name={fieldRenderProps.name}
									value={fieldRenderProps.value}
									onIonChange={(e) => {
										setTimeout(() => {
											fieldRenderProps.onChange(e); // TODO: this is to solve a race condition
										}, 0);
									}}
									// Other config
									style={{
										width: "100%",
										marginTop: "0.1rem",
									}}
								>
									{validChoices.map((choice, i) => (
										<IonItem
											key={`${field.name}-choice-${i}`}
											lines="none"
											disabled={isDisabled && isDisabled({ choice })}
											style={
												{
													"--inner-padding-start": "0",
													"--inner-padding-end": "1rem",
												} as React.CSSProperties
											}
										>
											<IonRadio
												data-testid={`${field.type}:checkbox:${choice.value}`}
												mode="md"
												labelPlacement="end"
												name={choice.label}
												value={choice.value}
												onIonBlur={fieldRenderProps.onBlur}
												justify="start"
												style={{
													marginInlineEnd: ".5rem",
													width: "100%",
												}}
											>
												<span className="ion-text-wrap" data-testid={`${field.type}:label:${choice.value}`}>
													{choice.label}
												</span>
											</IonRadio>
										</IonItem>
									))}
								</IonRadioGroup>
								{alert && (
									<IonNote
										data-testid={`${field.type}:note`}
										color={field.alertColor}
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "start",
											textAlign: "start",
											margin: ".25rem 0",
											fontSize: ".7rem",
										}}
									>
										<IonIcon
											icon={
												field.alertIcon === "informationCircleOutline" ? informationCircleOutline : alertCircleOutline
											}
											color={field.alertColor}
											size="small"
										/>
										<IonText>{alert}</IonText>
									</IonNote>
								)}
							</IonItem>
						);
					}}
				/>
			)}
		</div>
	);
};
const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(RadioGroup, propsAreEqual);
export default Memoized;
