import React, { useMemo } from "react";

import { IonList } from "@ionic/react";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import { Button } from "components/common/Button";
import { FilterFormContext, useFilterForm } from "components/common/Form/hooks/useFilterForm";
import { FormEngine } from "@arup-group/dhub-forms-engine";
import Form, { FormField } from "models/Form";
import FormRecord, { FormValues } from "models/FormRecord";
import { useAppSelector } from "store";
import RadioGroup from "components/common/Form/widgets/RadioGroup";
import Select from "components/common/Form/widgets/Select";
import Input from "components/common/Form/widgets/Input";

interface IProps {
	formEngine: FormEngine;
	record: FormRecord;
	form: Form;
	siblingRecords: FormRecord[];
	filterFields: FormField[];
	initialFilters: FormValues;
	setFilters: (values: FormValues) => void;
	resetFilters: () => void;
}

const Filters = React.forwardRef<HTMLFormElement, IProps>((props, ref) => {
	const { formEngine, record, form, siblingRecords, filterFields, initialFilters, setFilters, resetFilters } = props;
	const { t, i18n } = useTranslation();
	const history = useAppSelector((state) => state.history.list);

	const { engine, ...methods } = useFilterForm(formEngine, record, form, siblingRecords, initialFilters);

	const ctx = useMemo(() => ({ engine, ...methods }), [methods.formState.isDirty]);

	const { handleSubmit } = methods;

	const checkAndTransformData = (data: FormValues): FormValues => {
		const repeatableData: FormValues = get(data, [...history.map((it) => (!isNaN(parseInt(it)) ? "0" : it)), "0"]);
		const transformedData = getObjectInnerValue(repeatableData);
		return transformedData;
	};

	const getObjectInnerValue = (value: FormValues) => {
		let objectValues: FormValues = {};
		for (const key in value) {
			if (key !== "id" && value[key] !== null) {
				if (typeof value[key] === "string" && (value[key] as string).trim() === "") {
					continue;
				}
				if (typeof value[key] === "object") {
					objectValues = {
						...objectValues,
						...getObjectInnerValue(value[key] as never),
					};
				} else {
					objectValues[key] = value[key];
				}
			}
		}
		return objectValues;
	};

	return (
		<FilterFormContext.Provider value={ctx}>
			<form
				id="filters-form"
				ref={ref}
				onSubmit={handleSubmit(
					(data) => {
						setFilters(checkAndTransformData(data));
					},
					(errors) => {
						console.error(errors);
						throw new Error("Error submitting filters form");
					},
				)}
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					padding: "0rem 1.5rem",
				}}
			>
				<IonList
					style={{
						overflowY: "auto",
						flex: "1",
						position: "relative",
						padding: 0,
					}}
				>
					<div>
						{filterFields.map((field: FormField) => {
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							const { required, hardRequired, ...basicField } = field;
							switch (field.type) {
								case "radio":
									return <RadioGroup key={field.name} isFilterWidget={true} field={basicField as FormField<string>} />;
								case "dropdown":
									return <Select key={field.name} isFilterWidget={true} field={basicField as FormField<string>} />;
								default:
									return <Input key={field.name} isFilterWidget={true} field={basicField as FormField<string>} />;
							}
						})}
					</div>
				</IonList>
				<div style={{ marginBottom: "2rem", display: "flex", flexDirection: "column", marginInline: "1rem" }}>
					<Button onClickFunction={resetFilters} variant="empty" type="reset">
						{i18n.format(t("clear_filters"), "capitalizeEveryWord")}
					</Button>
					<Button variant="filled" type="submit">
						{i18n.format(t("apply_filters"), "capitalizeEveryWord")}
					</Button>
				</div>
			</form>
		</FilterFormContext.Provider>
	);
});
Filters.displayName = "Filters";
export default Filters;
