import React from "react";

import { IonBadge, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { checkmarkCircleOutline, ellipseOutline } from "ionicons/icons";

import { chevronRight } from "assets/icons";
import { FormField } from "../../../../models/Form";
import { FormValues, IDataValue } from "../../../../models/FormRecord";
import { useAppDispatch } from "../../../../store";
import { actions as historyActions } from "../../../../store/slices/history";
import { useSmartFieldCtx } from "../hooks/useSmartForm";

interface Props {
	field: FormField<FormValues[]>;
	parentInlineIds?: string[];
	siblingsAreFirstLevelGroups?: boolean;
}

const calcNumberOfRepeatables = (values: IDataValue) => {
	if (values && Array.isArray(values)) {
		return (values as FormValues[]).filter((it) => it && it.id && !it._is_deleted).length;
	} else {
		return 0;
	}
};

export const RepeatableGroup: React.FC<Props> = (props: Props) => {
	const { field, parentInlineIds = [] } = props;

	const dispatch = useAppDispatch();
	const { dig, currentValue, isCompleted } = useSmartFieldCtx(field);

	const { relevant, disabled, hidden } = dig;

	const numberOfRepeatables = calcNumberOfRepeatables(currentValue);

	return relevant && !hidden ? (
		<IonItem
			className="Group"
			id={`field-${field.name}`}
			button={true}
			detail={false}
			disabled={disabled}
			style={{
				backgroundColor: "var(--ion-color-secondary)",
				"--background": "var(--ion-color-secondary)",
				borderBottom: "1px solid #CBD5E0",
			}}
			lines="none"
			onClick={() => {
				if (parentInlineIds.length) {
					dispatch(historyActions.pushHistoryMany([...parentInlineIds, field.name]));
				} else dispatch(historyActions.pushHistory(field.name));
			}}
		>
			<IonLabel
				className="ion-text-wrap"
				style={{
					fontSize: "0.875rem",
					lineHeight: "1rem",
				}}
			>
				{field.label}
			</IonLabel>

			<IonBadge mode="ios" color="medium" slot="end" style={{ fontWeight: "normal", marginInline: "4px" }}>
				{numberOfRepeatables}
			</IonBadge>
			<IonIcon
				src={isCompleted ? checkmarkCircleOutline : ellipseOutline}
				slot="end"
				style={{
					color: isCompleted ? "var(--ion-color-success)" : "var(--ion-color-medium-tint)",
				}}
			/>
			<IonIcon
				src={chevronRight}
				slot="end"
				size="small"
				style={{
					color: "black",
					marginRight: "0",
					marginLeft: "10px",
				}}
			/>
		</IonItem>
	) : null;
};
