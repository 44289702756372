import React, { PropsWithChildren, useMemo, useState } from "react";

import FiltersModal from "components/modals/FiltersModal";
import Form from "models/Form";
import FormRecord, { FormValues } from "models/FormRecord";

import FormPage from "./components/FormPage";
import { Topbar } from "./components/Topbar/Topbar";
import { TopbarProps } from "./components/Topbar/TopbarProps";
import { FormContext, useSmartForm } from "./hooks/useSmartForm";
import { useFormErrorsCheck } from "./hooks/useFormErrorsCheck";
import { getPageFields } from "./utils/getPageFields";
import { SubmitFormFn } from "views/RecordView/RecordView";
import { useAppSelector } from "store";
import DigestedFieldLibrary from "models/DigestedFieldLibrary";
import { HashedDigestedFieldReferenceLibrary } from "@arup-group/dhub-forms-engine";

interface IProps extends PropsWithChildren {
	form: Form;
	record: FormRecord;
	siblingRecords: FormRecord[];
	onSubmit: SubmitFormFn;
	exitForm: () => void;
	onPrint: () => void;
	TopbarComponent?: React.FC<TopbarProps>;
}

type PropsType = IProps;

const FormComponent: React.FC<PropsType> = (props) => {
	const { form, record, siblingRecords, onSubmit, exitForm, onPrint, TopbarComponent = Topbar } = props;

	const dfl = useAppSelector((store) => store.form.dfl);
	const { engine, changesFlag, ...methods } = useSmartForm(form, record, siblingRecords, dfl);
	const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);

	useFormErrorsCheck(form, methods.formState);

	const ctx = useMemo(() => ({ engine, ...methods }), [methods.formState.isDirty, changesFlag]);

	const pages = getPageFields(form.fields, []);

	const onSubmitForm: SubmitFormFn = async (
		data: FormValues,
		changes: string[],
		history: string[],
		options = {
			exit: false,
			stay: false,
			completed: false,
		},
	) => {
		const unsavedChanges = [...ctx.unsavedChangesRef.current];
		if (unsavedChanges.length > 0) {
			// Save DFL
			setTimeout(async () => {
				const hashedDFL: HashedDigestedFieldReferenceLibrary = JSON.parse(
					JSON.stringify(ctx.engine.getHashedDigestedFieldLibrary()),
				);
				const newDfl = new DigestedFieldLibrary({
					record_id: record.id,
					dfl: hashedDFL.value,
					hash: hashedDFL.hash,
				});
				await newDfl.save();
				ctx.unsavedChangesRef.current.clear();
			}, 0);
		}
		await onSubmit(data, changes, history, options);
	};

	return (
		<FormContext.Provider value={ctx}>
			<TopbarComponent formFields={form.fields} exitForm={exitForm} onSubmit={onSubmitForm} onPrint={onPrint} />
			<form
				id={record.id}
				style={{ position: "relative", height: "calc(100% - 3rem)", backgroundColor: "transparent" }}
			>
				<FormPage childrenFields={form.fields} onSubmit={onSubmitForm} setFilterModalIsOpen={setFilterModalIsOpen} />
				{pages.map((it) => (
					<FormPage
						key={it.name}
						field={it}
						childrenFields={it.children || []}
						onSubmit={onSubmitForm}
						setFilterModalIsOpen={setFilterModalIsOpen}
					/>
				))}
				{props.children}
				{/* This button's sole purpose is to disable form submit on enter, which can be annoying here */}
				<button type="submit" disabled style={{ display: "none" }} />
			</form>
			<FiltersModal
				isOpen={filterModalIsOpen}
				onClose={() => {
					setFilterModalIsOpen(false);
				}}
				formEngine={ctx.engine}
				record={record}
				form={form}
				siblingRecords={siblingRecords}
			/>
		</FormContext.Provider>
	);
};

export default FormComponent;
