import React, { memo, PropsWithChildren } from "react";

import { IonSelect, IonSelectOption, IonItem, IonLabel, IonNote, IonIcon, IonText } from "@ionic/react";
import { Controller } from "react-hook-form";

import { Divider } from "components/common/Form/components/Divider";
import { FormField } from "models/Form";

import { alertCircleOutline, informationCircleOutline } from "ionicons/icons";
import { useAppSelector } from "store";
import { useSmartFieldCtx } from "../hooks/useSmartForm";
import { useFilterFieldCtx } from "../hooks/useFilterForm";

interface IProps {
	field: FormField<string>;
	isFilterWidget?: boolean;
}

type PropsType = IProps;
const Select: React.FC<PropsType> = (props) => {
	const { field, isFilterWidget } = props;

	const isFrozen = useAppSelector((state) => state.form.isFrozen);
	const { dig, control } = isFilterWidget ? useFilterFieldCtx(field) : useSmartFieldCtx(field);

	const { name, relevant, required, hardRequired, alert, disabled, choices, hidden } = dig;
	const validChoices = choices ?? [];
	if (field.sortChoices === "ascending") validChoices.sort((a, b) => a.label.localeCompare(b.label));
	else if (field.sortChoices === "descending") validChoices.sort((a, b) => b.label.localeCompare(a.label));

	return (
		<div
			className="Input"
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{relevant && !hidden && (
				<Controller
					name={name}
					control={control}
					defaultValue={field.defaultValue}
					rules={{
						required: hardRequired && relevant && validChoices.length !== 0,
					}}
					render={({ field: fieldRenderProps }) => {
						// Timeout prevents a form re-render before first render finishes
						setTimeout(() => {
							// If value is no longer valid
							if (
								fieldRenderProps.value &&
								!validChoices.map((ch) => ch.value).includes(fieldRenderProps.value as string)
							) {
								fieldRenderProps.onChange(null);
							}
							// If only one valid choice
							if (validChoices.length === 1 && fieldRenderProps.value !== validChoices[0].value) {
								fieldRenderProps.onChange(validChoices[0].value);
							}
						}, 0);

						return (
							<IonItem
								lines="none"
								cy-data="OptionSelector"
								disabled={isFrozen || disabled || validChoices.length === 0}
							>
								<IonLabel className="ion-text-wrap" position="stacked" mode="ios">
									{field.label}
									{(required || hardRequired) && <span style={{ color: "red" }}>&nbsp;*</span>}
								</IonLabel>
								<IonSelect
									label={""}
									labelPlacement="stacked"
									aria-label={field.label}
									data-testid={`${field.type}:input`}
									// React Hook Form Managed
									ref={fieldRenderProps.ref}
									name={fieldRenderProps.name}
									value={fieldRenderProps.value}
									onIonBlur={fieldRenderProps.onBlur}
									onIonChange={(e) => {
										setTimeout(() => {
											fieldRenderProps.onChange(e);
										}, 0);
									}}
									// Other config
									placeholder={field.description}
									multiple={false}
								>
									{validChoices.map((choice, idx) => (
										<IonSelectOption
											data-testid={`${field.type}:option:${choice.value}`}
											key={`${fieldRenderProps.name}-option-${idx}`}
											value={choice.value}
										>
											{choice.label}
										</IonSelectOption>
									))}
								</IonSelect>
								<Divider color="var(--ion-color-medium)" />
								{alert && (
									<IonNote
										data-testid={`${field.type}:note`}
										color={field.alertColor}
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "start",
											textAlign: "start",
											margin: ".25rem 0",
											fontSize: ".7rem",
										}}
									>
										<IonIcon
											icon={
												field.alertIcon === "informationCircleOutline" ? informationCircleOutline : alertCircleOutline
											}
											color={field.alertColor}
											size="small"
										/>
										<IonText>{alert}</IonText>
									</IonNote>
								)}
							</IonItem>
						);
					}}
				/>
			)}
		</div>
	);
};

const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(Select, propsAreEqual);
export default Memoized;
