import React, { memo, PropsWithChildren } from "react";

import { IonItem, IonLabel } from "@ionic/react";
import { useWatch } from "react-hook-form";

import { IImage } from "../../../../interfaces/IImage";
import { FormField } from "../../../../models/Form";
import Camera from "../components/Camera/Camera";
import Gallery from "../components/Gallery";
import { useSmartFieldCtx } from "../hooks/useSmartForm";
import { useAppSelector } from "store";

interface IProps {
	field: FormField<IImage[]>;
}

type PropsType = IProps;
const Images: React.FC<PropsType> = (props) => {
	const { field } = props;

	const isFrozen = useAppSelector((state) => state.form.isFrozen);
	const { dig, control, currentValue } = useSmartFieldCtx(field);

	const { relevant, required, hardRequired, disabled, hidden } = dig;

	useWatch({ name: field.name, control });
	const numberImages = ((currentValue as IImage[]) || []).filter((it) => !it._is_deleted).length;

	return (
		<div
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{relevant && !hidden && (
				<IonItem lines="none">
					<IonLabel
						className="ion-text-wrap capitalize-first-letter"
						position="stacked"
						mode="ios"
						style={{
							opacity: isFrozen || disabled ? 0.3 : 1,
						}}
					>
						{field.label} {field.max ? ` (${numberImages}/${field.max})` : ""}
						{(required || hardRequired) && <span style={{ color: "red" }}>&nbsp;*</span>}
					</IonLabel>
					<Gallery field={field} variant="image">
						{!isFrozen && !disabled && <Camera field={field} numberImages={numberImages} />}
					</Gallery>
				</IonItem>
			)}
		</div>
	);
};
const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(Images, propsAreEqual);
export default Memoized;
