import { IonIcon, IonItem, IonLabel, IonRouterLink } from "@ionic/react";
import { linkOutline } from "ionicons/icons";
import { FormField } from "models/Form";
import React from "react";
import { useSmartFieldCtx } from "../hooks/useSmartForm";

interface LinkProps {
	field: FormField<string>;
}

export const Link: React.FC<LinkProps> = (props) => {
	const { field } = props;

	const { dig } = useSmartFieldCtx(field);

	const { relevant, hidden } = dig;

	return relevant && !hidden ? (
		<div
			className="Link"
			id={`field-${field.name}`}
			style={{
				backgroundColor: "#f9f9f9",
			}}
		>
			<IonRouterLink
				data-testid={`${field.type}:value`}
				href={field.label} // TODO: tremenda chapuza...
				target={"_blank"}
				style={{ display: "flex" }}
			>
				<IonItem lines="none">
					<IonIcon src={linkOutline} color="dark" style={{ marginRight: ".5rem" }} />
					<IonLabel class="ion-text-wrap" color="dark" style={{ fontWeight: "600", textDecoration: "underline" }}>
						{
							// TODO: tremenda chapuza...
							field.shortLabel
						}
					</IonLabel>
				</IonItem>
			</IonRouterLink>
		</div>
	) : null;
};
